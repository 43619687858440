.ot-floating-button {
  display: none !important;
}

@layer base {
  html {
    @apply bg-white;
  }
  body {
    @apply overflow-x-hidden;
  }
  img {
    @apply aspect-auto max-h-full;
  }
  .square {
    @apply relative after:block after:pb-[100%] after:content-[''];
    &__inner {
      @apply absolute h-full w-full overflow-hidden;
    }
  }
}
