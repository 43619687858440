@layer components {
  /* HERO MODULE */
  .hero {
    @apply pt-16 md:pt-24;
    &__wrapper {
      @apply text-center;
      .headline {
        @apply mx-auto max-w-4xl;
      }
    }
  }
  /* HIW MODULE */
  .hiw {
    @apply pb-0 md:pb-6;
    &__wrapper {
      .step-wrapper {
        @apply relative flex items-center;
        &--1 {
          @apply mt-8 mb-48 flex-col gap-6 md:mt-16 md:flex-row md:gap-12;
          &__content {
            @apply flex flex-col px-8 text-center md:max-w-[240px] md:px-0 md:text-left;
          }
        }
        &--2 {
          @apply mb-72 flex-col gap-8;
          &__image {
            @apply mt-8 md:mt-16 w-40 h-40 md:w-44 md:h-44;
          }
          &__content {
            @apply flex flex-col text-center md:max-w-[400px];
            .body-text {
              @apply px-8;
            }
          }
        }
      }
      .arrow {
        @apply absolute block;
        &--left {
          @apply -rotate-12 sm:rotate-0 top-[105%] -right-10 w-[180px] md:top-[100%] md:right-[5%] md:w-[280px];
        }
        &--right {
          @apply top-[110%] left-[10%] w-[130px] md:top-[90%] md:w-[180px];
        }
      }
    }
  }
  /* AVATARS MODULE */
  .avatars {
    @apply bg-gradient-to-b from-white to-background pt-0 pb-6 md:pb-12;
    &__wrapper {
      @apply pb-8 md:pb-12 grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4;
      &__avatar {
        @apply relative overflow-hidden rounded-[16px] transition-all duration-150 ease-in-out hover:scale-[1.02] hover:shadow-basic md:rounded-[32px];
        figcaption {
          @apply E-M14 md:E-M16 absolute bottom-3 left-3 rounded-[8px] bg-white px-2 py-1.5 text-black transition-opacity duration-200 ease-out md:bottom-4 md:left-4 md:rounded-[16px] md:py-2 md:px-3;
        }
        &:hover figcaption {
          @apply opacity-0;
        }
      }
    }
  }
  /* NUMBERS MODULE */
  .numbers {
    @apply bg-background pb-0;
    &__wrapper {
      .cards-wrapper {
        @apply flex flex-col items-center gap-4 pt-6 lg:flex-row lg:justify-between lg:gap-0;
      }
    }
  }
  /* REVIEWS MODULE */
  .reviews {
    @apply bg-background after:pointer-events-none after:absolute after:bottom-0 after:left-0 after:h-1/4 after:md:h-1/2 after:w-full after:bg-gradient-to-t after:from-white after:to-transparent;

    &__wrapper {
      .badges-wrapper {
        @apply absolute bottom-0;
      }
      .cards-wrapper {
        @apply grid grid-cols-1 gap-4 md:block;
        column-count: 2;
        column-gap: 1.5rem;
        .card {
          @apply mb-0 md:mb-6 md:inline-block;
        }
      }
    }
  }
  /* FAQ MODULE */
  .faq {
    &__wrapper {
      .cards-wrapper {
        @apply mb-6 flex flex-col gap-8 pt-6 md:mb-12;
      }
    }
  }
}
