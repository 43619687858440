@font-face {
  font-family: 'EuclidCircularB';
  src: url('../fonts/EuclidCircularB-Medium.otf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EuclidCircularB';
  src: url('../fonts/EuclidCircularB-Regular.otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
